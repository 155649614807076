.CountAndStatus
    
{
    
    margin-top: 30px;
    margin-left: 30px;

display: flex;
flex-direction: row;

/*border-style: solid;*/
/*border-width: 1px;*/
/*border-color: red;*/

}

.CountAndStatus-Counter {
/*width: 35px;*/
height: 72px;
text-align: left;
font-family: Roboto-Medium;
font-size: 61.04px;
color: rgba(255,255,255,0.87);
letter-spacing: 0;

/*border-style: solid;*/
/*border-width: 1px;*/
/*border-color: red;*/

}
.CountAndStatus-Person {
    
    margin-top: 17px;
    margin-left: 10px;

width: 35px;
height: 38px;
/*background: #E4E3E3;*/

/*border-style: solid;*/
/*border-width: 1px;*/
/*border-color: red;*/

}


