body {
    background: #242323;

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
font-family: "Roboto-Regular";
src: url("./assets/fonts/Roboto-Regular.ttf") format("truetype");
}
@font-face {
font-family: "Roboto-Bold";
src: url("./assets/fonts/Roboto-Bold.ttf") format("truetype");
}
@font-face {
font-family: "Roboto-Medium";
src: url("./assets/fonts/Roboto-Medium.ttf") format("truetype");
}

/*@font-face {*/
/*font-family: "Poppins-Medium";*/
/*src: url("./assets/fonts/Poppins-Medium.ttf") format("truetype");*/
/*}*/
/*@font-face {*/
/*font-family: "Poppins-Light";*/
/*src: url("./assets/fonts/Poppins-Light.ttf") format("truetype");*/
/*}*/
/*@font-face {*/
/*font-family: "Poppins-Bold";*/
/*src: url("./assets/fonts/Poppins-Bold.ttf") format("truetype");*/
/*}*/
/*@font-face {*/
/*font-family: "Poppins-Regular";*/
/*src: url("./assets/fonts/Poppins-Regular.ttf") format("truetype");*/
/*}*/
@font-face {
font-family: "Poppins-SemiBold";
src: url("./assets/fonts/Poppins-SemiBold.ttf") format("truetype");
}
