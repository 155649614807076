.FirstColumn
    
{
display: flex;
flex-direction: column;
/*flex-wrap: wrap;*/
}

/*  flex-wrap: nowrap | wrap | wrap-reverse;*/

.FirstColumn-WindowStatusAndMotion
{
    display: flex;
    flex-direction: row;

}
