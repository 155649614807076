.TimeSeriesStatusValue

{
    margin-top: 10px;
    margin-left: 15px;
min-width: 42px;
height: 22px;
background: #6EA4B9;
border-radius: 3px;
display: flex;
flex-direction: row;

/*border-style: solid;*/
/*border-width: 1px;*/
/*border-color: red;*/

}

.TimeSeriesStatusValue-Text {
    margin-top: 3px;
    margin-left: 10.5px;
/*width: 22px;*/
padding-right: 9px;
height: 15px;
text-align: left;
opacity: 0.87;

font-family: Roboto;
font-size: 14px;

/*font-family: Poppins-Regular;*/
/*font-size: 10px;*/

color: #FFFFFF;
letter-spacing: 0;
/*text-align: center;*/

/*border-style: solid;*/
/*border-width: 1px;*/
/*border-color: red;*/

}
