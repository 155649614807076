.WindowStatus

{
    margin-top: 45px;
    margin-left: 45px;

width: 315px;
height: 545px;
background: rgba(255,255,255,0.05);
box-shadow: 0 4px 16px 0 rgba(0,0,0,0.06);
border-radius: 8px;
display: flex;
flex-direction: column;

/*border-style: solid;*/
/*border-width: 1px;*/
/*border-color: red;*/

}

.WindowStatus-Heading {

      margin-top: 30px;
      margin-left: 30px;

/*width: 243px;*/
/*height: 15px;*/
text-align: left;
font-family: Roboto-Bold;
font-size: 20px;
color: rgba(255,255,255,0.87);
letter-spacing: 2.14px;
text-align: left;
line-height: 15px;

/*border-style: solid;*/
/*border-width: 1px;*/
/*border-color: red;*/

}

.WindowAndActivity-Window {
    
    margin-top: 50px;
    margin-left: 88px;

width: 113px;
height: 109px;

/*border-style: solid;*/
/*border-width: 1px;*/
/*border-color: red;*/

}

.WindowStatus-ActivityStatus {
  
    margin-top: 45px;
    margin-left: 48px;

/*width: 113px;*/
height: 19px;
text-align: left;
font-family: Roboto-Medium;
font-size: 20px;
color: #FFB266;
letter-spacing: 1.71px;
text-align: left;

/*border-style: solid;*/
/*border-width: 1px;*/
/*border-color: red;*/

}


