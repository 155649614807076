.TimeSeriesStatus
    
{
/*min-width: 250px;*/
height: 42px;
background: #536A73;
border-radius: 0 0 8px 8px;
display: flex;
flex-direction: row;

/*border-style: solid;*/
/*border-width: 1px;*/
/*border-color: red;*/

}


