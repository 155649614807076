.TimeSeries

{
/*    margin-top: 0px;*/
/*    margin-left: 45px;*/
    margin-top: 45px;
    margin-left: 45px;
/*min-width: 250px;*/
width: 625px;
height: 250px;
background: rgba(255,255,255,0.05);
box-shadow: 0 4px 16px 0 rgba(0,0,0,0.06);
border-radius: 8px;
display: flex;
flex-direction: column;

/*border-style: solid;*/
/*border-width: 1px;*/
/*border-color: red;*/

}

.TimeSeries-Heading {
    margin-top: 25px;
    margin-left: 15px;
/*width: 122px;*/
height: 15px;
text-align: left;
font-family: Roboto-Bold;
font-size: 20px;
/*font-family: Roboto-Medium;*/
/*font-size: 14px;*/
color: rgba(255,255,255,0.87);
letter-spacing: 1.5px;
text-align: left;
line-height: 15px;

/*border-style: solid;*/
/*border-width: 1px;*/
/*border-color: red;*/

}
.TimeSeries-SubHeading {
    margin-top: 4px;
    margin-left: 15px;
/*width: 108px;*/
height: 17px;
text-align: left;
font-family: Roboto-Regular;
font-size: 12px;
color: rgba(255,255,255,0.38);
letter-spacing: 0;

/*border-style: solid;*/
/*border-width: 1px;*/
/*border-color: red;*/

}
.TimeSeries-Chart {
    margin-top: 7px;
    margin-left: 0px;
/*width: 675px;*/
height: 117px;

/*border-style: solid;*/
/*border-width: 1px;*/
/*border-color: red;*/

}
