.WindowAndActivity

{
display: flex;
flex-direction: column;

  margin-top: 50px;
  margin-left: 48px;

/*  border-style: solid;*/
/*  border-width: 1px;*/
/*  border-color: red;*/


}

/*.WindowAndActivity-Window {*/
/*width: 102px;*/
/*height: 98px;*/
/**/
/*border-style: solid;*/
/*border-width: 1px;*/
/*border-color: red;*/
/**/
/*}*/
