.Header {
  min-width: 1441px;
  height: 70px;
  background: #ffffff;
  box-shadow: 0 8px 28px 0 rgba(72, 89, 102, 0.1);
  display: flex;
  flex-direction: row;
}

.Header-Code {
    border: solid 1px;
    background: rgba(72, 89, 102, 0.9);
    color: white;
    border-radius: 8px;
    padding: 2px 4px 2px 4px;
}

.Header-SCOLogo {
  margin-top: 4px;
  margin-left: 20px;
  width: 64px;
  height: 64px;
  background: rgba(255, 255, 255, 0.14);
}

.Header-Text {
  margin-top: 12px;
  margin-left: 20px;
  font-family: Roboto-Bold;
  font-size: 40px;
  color: rgba(0,0,0,0.7);
}
