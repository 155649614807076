.Motion

{
    margin-top: 45px;
    margin-left: 45px;
/*min-width: 250px;*/
width: 315px;
height: 545px;
background: rgba(255,255,255,0.05);
box-shadow: 0 4px 16px 0 rgba(0,0,0,0.06);
border-radius: 8px;
display: flex;
flex-direction: column;

/*border-style: solid;*/
/*border-width: 1px;*/
/*border-color: red;*/

}

.TimesAndMotionChart {
    
    margin-top: 30px;
    margin-left: 10px;

    display: flex;
    flex-direction: row;
    
/*    border-style: solid;*/
/*    border-width: 1px;*/
/*    border-color: red;*/


}

.Motion-Heading {
          margin-top: 30px;
          margin-left: 30px;

    /*width: 243px;*/
    /*height: 15px;*/
    text-align: left;
    font-family: Roboto-Bold;
    font-size: 20px;
    color: rgba(255,255,255,0.87);
    letter-spacing: 2.14px;
    text-align: left;
    line-height: 15px;

/*border-style: solid;*/
/*border-width: 1px;*/
/*border-color: red;*/
}

.Motion-SubHeading {
    margin-top: 4px;
    margin-left: 15px;
/*width: 108px;*/
height: 17px;
text-align: left;
font-family: Roboto-Regular;
font-size: 12px;
color: rgba(255,255,255,0.38);
letter-spacing: 0;
text-align: right;

/*border-style: solid;*/
/*border-width: 1px;*/
/*border-color: red;*/

}

.Motion-Chart {
    margin-top: -20px;
    margin-left: 10px;
width: 230px;
height: 350px;

/*border-style: solid;*/
/*border-width: 1px;*/
/*border-color: red;*/

}
