.TimeAndDate

{
display: flex;
flex-direction: column;

/*border-style: solid;*/
/*border-width: 1px;*/
/*border-color: red;*/

}

.TimeAndDate-CurrentTime {

    margin-top: 68px;
    margin-left: 210px;

width: 241px;
height: 72px;
text-align: left;
opacity: 0.87;
font-family: Roboto-Medium;
font-size: 61.04px;
color: rgba(255,255,255,0.87);
letter-spacing: 0;

/*border-style: solid;*/
/*border-width: 1px;*/
/*border-color: red;*/

}
.TimeAndDate-DayNameAndDate {

    margin-top: 17px;
    margin-left: 165px;

/*width: 338px;*/
height: 33px;
text-align: left;
font-family: Roboto-Medium;
font-size: 25px;
color: rgba(255,255,255,0.60);
letter-spacing: 0;
line-height: 22px;

/*border-style: solid;*/
/*border-width: 1px;*/
/*border-color: red;*/

}
