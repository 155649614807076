.TimeSeriesTimes

{
    margin-top: 26px;
    margin-left: 15px;
    margin-right: 15px;
    
display: flex;
flex-direction: row;
justify-content: space-between;

/*border-style: solid;*/
/*border-width: 1px;*/
/*border-color: red;*/

}

/*  justify-content: flex-start | flex-end | center | space-between | space-around | space-evenly | start | end | left | right ... + safe | unsafe;*/

.TimeSeriesTimes-Time1 {
width: 25px;
height: 17px;
text-align: left;
font-family: Roboto;
font-size: 16px;
color: rgba(255,255,255,0.38);
letter-spacing: 0;
/*text-align: right;*/
}
.TimeSeriesTimes-Time2 {
/*    margin-top: 0px;*/
/*    margin-left: 26px;*/
width: 24px;
height: 17px;
text-align: left;
font-family: Roboto;
font-size: 16px;
color: rgba(255,255,255,0.38);
letter-spacing: 0;
/*text-align: right;*/
}
.TimeSeriesTimes-Time3 {
/*    margin-top: 0px;*/
/*    margin-left: 26px;*/
width: 26px;
height: 17px;
text-align: left;
font-family: Roboto;
font-size: 16px;
color: rgba(255,255,255,0.38);
letter-spacing: 0;
/*text-align: right;*/
}
.TimeSeriesTimes-Time4 {
/*    margin-top: 0px;*/
/*    margin-left: 26px;*/
width: 23px;
height: 17px;
text-align: left;
font-family: Roboto;
font-size: 16px;
color: rgba(255,255,255,0.38);
letter-spacing: 0;
/*text-align: right;*/
}
.TimeSeriesTimes-Time5 {
/*    margin-top: 0px;*/
    margin-right: 15px;
width: 25px;
height: 17px;
text-align: left;
font-family: Roboto-Bold;
font-size: 16px;
color: rgba(255,255,255,0.60);
letter-spacing: 0;
/*text-align: right;*/
}
