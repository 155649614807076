.AictivityTextW

{
display: flex;
flex-direction: column;

/*  margin-left: 30px;*/

/*  border-style: solid;*/
/*  border-width: 1px;*/
/*  border-color: red;*/

}

.AictivityTextW-ActivityHeader {
/*width: 126px;*/
/*height: 19px;*/
text-align: left;
font-family: Roboto-Medium;
font-size: 20px;
color: rgba(255,255,255,0.87);
letter-spacing: 0;

/*border-style: solid;*/
/*border-width: 1px;*/
/*border-color: red;*/

}

.AictivityTextW-ActivitiyItem1 {

  margin-top: 15px;

/*width: 126px;*/
/*height: 16px;*/
text-align: left;
font-family: Roboto-Medium;
font-size: 20px;
color: rgba(255,255,255,0.38);
letter-spacing: 0;

/*border-style: solid;*/
/*border-width: 1px;*/
/*border-color: red;*/

}

.AictivityTextW-ActivitiyItem2 {

  margin-top: 15px;

/*width: 126px;*/
/*height: 16px;*/
text-align: left;
font-family: Roboto-Medium;
font-size: 20px;
color: rgba(255,255,255,0.38);
letter-spacing: 0;

/*border-style: solid;*/
/*border-width: 1px;*/
/*border-color: red;*/

}

.AictivityTextW-ActivitiyItem3 {

  margin-top: 15px;

/*width: 126px;*/
/*height: 16px;*/
text-align: left;
font-family: Roboto-Medium;
font-size: 20px;
color: rgba(255,255,255,0.38);
letter-spacing: 0;

/*border-style: solid;*/
/*border-width: 1px;*/
/*border-color: red;*/

}

.AictivityTextW-ActivitiyItem4 {

  margin-top: 15px;

/*width: 126px;*/
/*height: 16px;*/
text-align: left;
font-family: Roboto-Medium;
font-size: 20px;
color: rgba(255,255,255,0.38);
letter-spacing: 0;

/*border-style: solid;*/
/*border-width: 1px;*/
/*border-color: red;*/

}
