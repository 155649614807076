.Weather
    
{
    
    margin-top: 45px;
    margin-left: 45px;

min-width: 675px;
height: 250px;
background: rgba(255,255,255,0.05);
box-shadow: 0 4px 16px 0 rgba(0,0,0,0.10);
border-radius: 8px;
display: flex;
flex-direction: row;

/*border-style: solid;*/
/*border-width: 1px;*/
/*border-color: red;*/


/*justify-content: space-around;*/
/*align-items: center;*/

}

/*  align-items: stretch | flex-start | flex-end | center | baseline | first baseline | last baseline | start | end | self-start | self-end + ... safe | unsafe;*/
/*  justify-content: flex-start | flex-end | center | space-between | space-around | space-evenly | start | end | left | right ... + safe | unsafe;*/
