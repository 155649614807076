.Cards

{
/*    margin-top: 45px;*/
/*    margin-left: 45px;*/
/*    margin-right: 45px;*/
    margin-bottom: 45px;
/*    padding: 45px;*/
width:  1440px;
display: flex;
flex-direction: row;

/*border-style: solid;*/
/*border-width: 1px;*/
/*border-color: red;*/

}
